import { Group,Object3D,Vector3 } from 'three';
import Land from './Land/Land.js';
import Flower from './Flower/Flower.js';
import Markers from './Markers/Markers.js';
import BasicLights from './Lights.js';
import positions from '../models/locations'
import gsap from 'gsap';

export default class SeedScene extends Group {
  
  constructor(indexPosition) {
    super();

    this.indexPosition = 0

    const land = new Land();
    const flower = new Flower();
    const markers = new Markers();
    const lights = new BasicLights();
    this.add(land, markers, lights);
  }
  updatePosition(newPosition){ 
    gsap.to(this.rotation,{
      x:newPosition.x,
      y:newPosition.y,
      z:newPosition.z,
      ease: "none",
      duration:1,
    })
  }

  defaultPosition(){
    gsap.to(this.rotation,{
      x:0,
      y:0,
      z:0,
      ease: "none",
      duration:1,
      delay:0.5
    })  
  }

  getPosition(){
    console.log('Position Object : ',this.position)
  }
}