/**
 * entry.js
 *
 * This is the first file loaded. It sets up the Renderer,
 * Scene and Camera. It also starts the render loop and
 * handles window resizes.
 *
 */

import {
  WebGLRenderer,
  PerspectiveCamera,
  Scene,
  Vector3,
  TextureLoader,
  MeshBasicMaterial,
  LoadingManager,
  Mesh,
  Raycaster,
  SphereGeometry,
  Vector2,
  PMREMGenerator
} from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import SeedScene from "./objects/Scene.js";
import CloudScene from "./objects/Scenecloud.js";
import backgroundImage from "./assets/background_autumn.jpg";
import logoBeach from "./assets/logo_beach.png";
import logoImage from "./assets/logo_buddha.png";
import "./assets/style.scss"

import gsap, { wrap } from 'gsap'
import locations from './models/locations'

import markers from './models/markers'


const { innerHeight, innerWidth } = window;


const scene = new Scene();
const EARTH_RADIUS = 1;
const camera = new PerspectiveCamera(60,1,0.1,1000 );
const renderer = new WebGLRenderer({ antialias: true });
const labelRenderer = new CSS2DRenderer();
const seedScene = new SeedScene();
const cloudScene = new CloudScene();
const rgbeLoader = new RGBELoader();
const objects = []; 
const manager = new LoadingManager();

var finalLogo = '';

// scene
scene.add(seedScene);
scene.add(cloudScene);


camera.position.set(.8, .1, 3);
// scene.position.set()


//Markers
// let geometry = new SphereGeometry(1, 32, 16);
// let material = new MeshBasicMaterial({color: 0xff0000});
// let sp = new Mesh(geometry, material);
// scene.add(sp);
// sp.scale.set(0.02,0.02,0.02);

// // 'camera':{x: 0.060196548593959856, y: 0.834413967785356, z: 1.3254164050287935},
// // 'rotation':{x:0.4,y:0.3,z:0.5},

// sp.position.set(-1.4, 0.2, 0.6);


// renderer
renderer.setPixelRatio(window.devicePixelRatio);
renderer.setClearColor(0xffffff, 1);


//Background
const loaderBackground = new TextureLoader().load(backgroundImage,()=>{
  cover( loaderBackground, window.innerWidth / window.innerHeight );
  scene.background = loaderBackground;
});
loaderBackground.matrixAutoUpdate = false;


//BACKGROUND HDRI
// new RGBELoader().load(backgroundImage, texture => {
//   const gen = new PMREMGenerator(renderer)
//   const envMap = gen.fromEquirectangular(texture).texture
//   scene.environment = envMap
//   scene.background = envMap
  
//   texture.dispose()
//   gen.dispose()
// })

// Background Image Panorama
// var geometry = new SphereGeometry( 1024, 60, 40 );
// geometry.scale( - 1, 1, 1 );
// var material = new MeshBasicMaterial( {
//   map: new TextureLoader().load( backgroundImage )
// } );
// let mesh = new Mesh( geometry, material );
// scene.add( mesh );




labelRenderer.setSize( window.innerWidth, window.innerHeight );
labelRenderer.domElement.style.position = 'absolute';
labelRenderer.domElement.style.top = '0px';
document.body.appendChild( labelRenderer.domElement );

//Controls
const controls = new OrbitControls(camera, labelRenderer.domElement);
controls.enableDamping = true;
controls.minDistance = 1;
controls.maxDistance = 3;
controls.enablePan = false;


// resize
const windowResizeHanlder = () => {
  const { innerHeight, innerWidth } = window;
  // cover( loaderBackground, innerWidth / innerHeight );
  renderer.setSize(innerWidth, innerHeight);
  camera.aspect = innerWidth / innerHeight;
  camera.updateProjectionMatrix();
};
windowResizeHanlder();
window.addEventListener("resize", windowResizeHanlder);

// find intersections
var raycaster = new Raycaster();
var mouse = new Vector2();


const initAll = () =>{
  gsap.to(camera.position,{
    x: 0.8, y: 0.1, z: 3, //Zoom
    duration:1
    })

  seedScene.defaultPosition();
}

function onDocumentMouseMove(event) {

  const wrapperHovered = document.getElementById('wrapper-name-hovered');
  const divHovered = document.getElementById('bloc-name-hovered');

  var mouse = new Vector2();
  mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
  mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;

  raycaster.setFromCamera(mouse, camera);

  const intersects = raycaster.intersectObjects( scene.children );
  if(intersects.length == 0){

  }else{
    const currentMarker = intersects[0].object.name;
    // console.log(currentMarker);
    
    document.body.style.cursor = "default";

    if(markers.includes(currentMarker)) {

      document.body.style.cursor = "pointer";

      const positionRotation = locations.filter(p => (p.id == currentMarker || p.id_marker_1 == currentMarker || p.id_marker_2 == currentMarker))[0];
      // console.log(positionRotation);
      if(positionRotation){
        const nameHovered1 = (positionRotation.data) ? positionRotation.data.name : '' ;
        const nameHovered2 = (positionRotation.data_2) ? ' & <br/> '+ positionRotation.data_2.name : '';
        const nameHovered3 = (positionRotation.data_3) ? ' & <br/> '+ positionRotation.data_3.name : '';
        const nameHovered4 = (positionRotation.data_4) ? ' & <br/> '+ positionRotation.data_4.name : '';
        const nameHovered = "".concat(nameHovered1,nameHovered2,nameHovered3,nameHovered4)
        wrapperHovered.style.display='flex'
        divHovered.innerHTML=nameHovered;
      }



    } else {
      wrapperHovered.style.display='none'
      document.body.style.cursor = "default";

    }

  }

}





// mouse listener
document.addEventListener(
  "mousedown",
  function (event) {

    //GET POSITIONS
    // console.log('Postion Camera : ',camera.position)
    const logo = document.body.querySelector('.logo');


    if(event.srcElement.className=='close'){
      controls.enabled = true;
      initAll();
      const wrapper = document.body.querySelector('.wrapper')
      wrapper.classList.remove('active');
      wrapper.classList.add('unactive')
      logo.src = '';
    }



    var rect = renderer.domElement.getBoundingClientRect();
    mouse.x = ((event.clientX - rect.left) / (rect.width - rect.left)) * 2 - 1;
    mouse.y = -((event.clientY - rect.top) / (rect.bottom - rect.top)) * 2 + 1;

    const wrapperHTML = document.body.querySelector('.wrapper');

    const nameData = document.body.querySelector('.name');
    const addressData = document.body.querySelector('.address');
    const telephoneData = document.body.querySelector('.telephone');
    const mailData = document.body.querySelector('.mail');
    const websiteData = document.body.querySelector('.website');


    const nameData2 = document.body.querySelector('.name_2');
    const addressData2 = document.body.querySelector('.address_2');
    const telephoneData2 = document.body.querySelector('.telephone_2');
    const mailData2 = document.body.querySelector('.mail_2');
    const websiteData2 = document.body.querySelector('.website_2');

    const nameData3 = document.body.querySelector('.name_3');
    const addressData3 = document.body.querySelector('.address_3');
    const telephoneData3 = document.body.querySelector('.telephone_3');
    const mailData3 = document.body.querySelector('.mail_3');
    const websiteData3 = document.body.querySelector('.website_3');

    const nameData4 = document.body.querySelector('.name_4');
    const addressData4 = document.body.querySelector('.address_4');
    const telephoneData4 = document.body.querySelector('.telephone_4');
    const mailData4 = document.body.querySelector('.mail_4');
    const websiteData4 = document.body.querySelector('.website_4');



    raycaster.setFromCamera(mouse, camera);

    const intersects = raycaster.intersectObjects( scene.children );
    const currentMarker = intersects[0].object.name;


    const zoomCamera = 1.8

    // console.log(currentMarker);

    if(markers.includes(currentMarker)){
      // console.log('existe');

      const positionRotation = locations.filter(p => (p.id == currentMarker || p.id_marker_1 == currentMarker || p.id_marker_2 == currentMarker))[0];
      if(positionRotation){

        finalLogo=(positionRotation.logo=='beach') ? logoBeach : logoImage; 

        logo.src=finalLogo;
        nameData2.innerHTML='';
        addressData2.innerHTML='';
        telephoneData2.innerHTML='';
        mailData2.innerHTML='';
        websiteData2.innerHTML='';

        nameData3.innerHTML='';
        addressData3.innerHTML='';
        telephoneData3.innerHTML='';
        mailData3.innerHTML='';
        websiteData3.innerHTML='';

        nameData4.innerHTML='';
        addressData4.innerHTML='';
        telephoneData4.innerHTML='';
        mailData4.innerHTML='';
        websiteData4.innerHTML='';

        nameData.innerHTML = positionRotation.data.name
        addressData.innerHTML = positionRotation.data.address
        telephoneData.innerHTML = positionRotation.data.tel
        mailData.innerHTML = positionRotation.data.email
        websiteData.innerHTML = positionRotation.data.website

        if(positionRotation.data_2){
          nameData2.innerHTML = positionRotation.data_2.name
          addressData2.innerHTML = positionRotation.data_2.address
          telephoneData2.innerHTML = positionRotation.data_2.tel
          mailData2.innerHTML = positionRotation.data_2.email
          websiteData2.innerHTML = positionRotation.data_2.website
        }

        if(positionRotation.data_3){
          nameData3.innerHTML = positionRotation.data_3.name
          addressData3.innerHTML = positionRotation.data_3.address
          telephoneData3.innerHTML = positionRotation.data_3.tel
          mailData3.innerHTML = positionRotation.data_3.email
          websiteData3.innerHTML = positionRotation.data_3.website
        }

        if(positionRotation.data_4){
          nameData4.innerHTML = positionRotation.data_4.name
          addressData4.innerHTML = positionRotation.data_4.address
          telephoneData4.innerHTML = positionRotation.data_4.tel
          mailData4.innerHTML = positionRotation.data_4.email
          websiteData4.innerHTML = positionRotation.data_4.website
        }


        wrapperHTML.classList.add('active')

        gsap.to(camera.position,{
          x: 0.2, y: 0.3, z: zoomCamera, //Zoom
          duration:1.5,
          })
        
        seedScene.updatePosition(positionRotation.rotation)
        
        // controls.enabled = false;
      }

    }else{
      const wrapper = document.body.querySelector('.wrapper')
      wrapper.classList.remove('active');
      wrapper.classList.add('unactive')
    }
    //GET ALL POSITIONS :
    // seedScene.getPosition();

    if(event.srcElement.innerHTML=='Reset'){
      initAll();
    }


  }
);


document.addEventListener('pointermove', onDocumentMouseMove);


function animate(timeStamp) {
  requestAnimationFrame(animate);
  renderer.render(scene, camera);
  

  labelRenderer.render( scene, camera );
  cloudScene.update(timeStamp) 
  
  controls.update();
}
animate();




function cover( texture, aspect ) {

  var imageAspect = 4424 / 2077;

  // console.log(aspect)

  if ( aspect < imageAspect ) {

      texture.matrix.setUvTransform( 0, 0, aspect / imageAspect, 1, 0, 0.5, 0.5 );

  } else {

      texture.matrix.setUvTransform( 0, 0, 1, imageAspect / aspect, 0, 0.5, 0.5 );

  }

}

document.body.style.margin = 0;
var div = document.createElement("div");
var style = document.createElement('style');

const close = document.createElement('a')
close.innerHTML="X";
close.classList.add('close')



style.type = 'text/css';
div.classList.add('wrapper')
document.body.appendChild(style)
document.body.appendChild(div);

var loading = document.createElement("div");
loading.classList.add('loader')

var loadingImage = document.createElement("img");
loadingImage.src="https://www.buddhabar.com/wp-content/uploads/2019/06/Jun-4-2019-2_55-PM-online-video-cutter.com-1_1.gif"

var loadingBarre = document.createElement('div');
loadingBarre.classList.add('loading-barre');
loadingBarre.innerHTML='<span id="progressbarre"></span>'


const listing = document.createElement("div");
listing.classList.add('data-infos')
listing.innerHTML = `
<h2 class="name"></h2>
<p class="address"></p>
<p class="telephone"></p> 
<p class="mail"></p>
<p class="website"></p>

<h2 class="name_2"></h2>
<p class="address_2"></p>
<p class="telephone_2"></p> 
<p class="mail_2"></p>
<p class="website_2"></p>

<h2 class="name_3"></h2>
<p class="address_3"></p>
<p class="telephone_3"></p> 
<p class="mail_3"></p>
<p class="website_3"></p>

<h2 class="name_4"></h2>
<p class="address_4"></p>
<p class="telephone_4"></p> 
<p class="mail_4"></p>
<p class="website_4"></p>
`;


const logoDiv = document.createElement('div')
logoDiv.innerHTML='<div class="text-center logo-buddha"><img class="logo" src="'+finalLogo+'" /></div>'


const wrapperNameHovered = document.createElement('div')
wrapperNameHovered.setAttribute('id','wrapper-name-hovered')
wrapperNameHovered.classList.add('wrapper-name-hovered')
document.body.appendChild(wrapperNameHovered);
const blocNameHovered = document.createElement('div')
blocNameHovered.setAttribute('id','bloc-name-hovered')
document.body.querySelector('.wrapper-name-hovered').appendChild(blocNameHovered)

document.body.querySelector('.wrapper').appendChild(close)
document.body.querySelector('.wrapper').appendChild(logoDiv)
document.body.querySelector('.wrapper').appendChild(listing)

document.body.appendChild(loading);
document.body.querySelector('.loader').appendChild(loadingImage)
document.body.querySelector('.loader').appendChild(loadingBarre)



document.body.appendChild(renderer.domElement);
