import { Group,LoadingManager } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import MODEL from './Land_beach.glb';
const manager = new LoadingManager();

export default class Land extends Group {
  constructor() {
    const loader = new GLTFLoader(manager);
    
    super();

    this.name = 'land';


    manager.onProgress = function ( item, loaded, total ) {
      console.log('Item current load : '+item)
      let percentageLoading = loaded / total * 100;
      const progressBarre = document.getElementById('progressbarre');
      progressBarre.style.width=percentageLoading+'%'
    };

    loader.load(MODEL, (gltf)=>{
      this.add(gltf.scene);
      // console.log('is loaded')
        const loaderElement = document.body.querySelector('.loader')
        loaderElement.classList.add('disable')
    },function(xhr){
        // console.log(xhr)
    });
  }
}
