const markers = [
  'marker_5_dubai002',
  'marker_5_dubai002_1',
  'marker_5_dubai001',
  'marker_5_dubai001_1',
  'marker_3_Belgrade001',
  'marker_3_Belgrade001_1',
  'marker_3_Belgrade002',
  'marker_3_Belgrade002_1',
  'marker_3_Belgrade005',
  'marker_3_Belgrade005_1',
  'marker_3_Belgrade004',
  'marker_3_Belgrade004_1',
  'marker_3_Belgrade003',
  'marker_3_Belgrade003_1',
  'marker_21_mazagran_1',
  'marker_21_mazagran_2',
  'marker_10_pragues_1',
  'marker_10_pragues_2',
  'marker_9_paris_1',
  'marker_9_paris_2',
  'marker_8_monte_carlo_1',
  'marker_8_monte_carlo_2',
  'marker_7_marrakech_1',
  'marker_7_marrakech_2',
  'marker_6_londres_1',
  'marker_6_londres_2',
  'marker_5_dubai_1',
  'marker_5_dubai_2',
  'marker_4_doha_1',
  'marker_4_doha_2',
  'marker_3_Belgrade_1',
  'marker_3_Belgrade_2',
  'marker_2_Caracas_1',
  'marker_2_Caracas_2',
  'marker_12_Sharm_El_Sheikh_1',
  'marker_12_Sharm_El_Sheikh_2',
  'marker_1_Astana_1',
  'marker_1_Astana_2',
  '1_Astana',
  '2_Caracas',
  '3_Belgrade',
  '4_doha',
  '5_dubai',
  '6_londres',
  '7_marrakech',
  '8_monte_carlo',
  '9_paris',
  '10_pragues',
  '11_Saint-Pétersbourg',
  '12_Sharm_El_Sheikh',
  '26_marrakech']

export default markers