//'1_Astana','2_Caracas','3_Belgrade','4_doha', '5_dubai', '6_londres', '7_marrakech', '8_monte_carlo', '9_paris', '10_pragues', '11_Saint-Pétersbourg', '12_Sharm_El_Sheikh'
const positions = [

  {
    'id':'1_Astana',
    'id_marker_1': 'marker_1_Astana_1',
    'id_marker_2': 'marker_1_Astana_2',
    'camera':{x: 0.060196548593959856, y: 0.834413967785356, z: 1.3254164050287935},
    'rotation':{x:0.8,y:-1.1,z:0.4},
    'data':{
      'name':'Astana',
      'address':'Syganak St 60/5, Astana 020000, Kazakhstan',
      'tel':'+7 701 707 1230',
      'email':'<a href="https://buddhabar-astana.com/" target="_blank">Reservation</a>',
      'website':'<a href="https://buddhabar-astana.com/" target="_blank">Website</a>'
    },
    'logo':'buddha'
  },
  {
    'id':'2_Caracas',
    'id_marker_1': 'marker_2_Caracas_1',
    'id_marker_2': 'marker_2_Caracas_2',
    'camera':{x: 0.060196548593959856, y: 0.834413967785356, z: 1.3254164050287935},
    'rotation':{x:-0.2,y:1.2,z:0.1},
    'data':{
      'name':'CARACAS',
      'address':'Final Calle París, Las Mercedes, Municipio Baruta, Caracas.',
      'tel':'+58 212 9942559',
      'email':'<a href="mailto:info@buddhabarccs.com">Reservation</a>',
      'website':'<a href="http://buddhabarccs.com/" target="_blank">Website</a>'
    },
    'logo':'buddha'
  },
  {
    'id':'3_Belgrade',
    'id_marker_1': 'marker_3_Belgrade_1',
    'id_marker_2': 'marker_3_Belgrade_2',
    'camera':{x: 0.060196548593959856, y: 0.834413967785356, z: 1.3254164050287935},
    'rotation':{x:0.6,y:0.2,z:0.5},
    'data':{
      'name':'Belgrade',
      'address':'Hercegovačka 14a/b, Beograd 11000, Serbie',
      'tel':'+381 11 4125601',
      'email':'<a href="https://buddhabarbeograd.rs/#reservation" target="_blank">Reservation</a>',
      'website':'<a href="https://buddhabarbeograd.rs/" target="_blank">Website</a>'
    },
    'logo':'buddha'
  },
  {
    'id':'4_doha',
    'id_marker_1': 'marker_4_doha_1',
    'id_marker_2': 'marker_4_doha_2',
    'camera':{x: 0.060196548593959856, y: 0.834413967785356, z: 1.3254164050287935},
    'rotation':{x:0.3,y:-0.7,z:0.2},
    'data':{
      'name':'Doha',
      'address':'Marina of The Ritz-Carlton, West Bay Lagoon Doha 23400',
      'tel':'+ 974-5080-4421',
      'email':'<a href="https://www.eat2eat.com/Marriott/RitzCarlton/Pages/Reservation.aspx?relId=NAA3ADUAMwA=&lang=0&pro=0&src=IPS" target="_blank">Reservation</a>',
      'website':'<a href="https://www.bloungedoha.com/" target="_blank">Website</a>'
    },
    'logo':'buddha'
  },
  {
    'id':'5_dubai',
    'id_marker_1': 'marker_5_dubai_1',
    'id_marker_2': 'marker_5_dubai_2',
    'camera':{x: 0.060196548593959856, y: 0.834413967785356, z: 1.3254164050287935},
    'rotation':{x:0.3,y:-0.8,z:0.3},
    'data':{
      'name':'DUBAÏ',
      'address':'P.O Box 118500 - Dubaï, United Arab Emirates',
      'tel':'+800 325 45 454',
      'email':'<a href="https://www.eat2eat.com/SWBooking/Pages/Reservation.aspx?relId=NAA0ADMAMwA=&lang=0&pro=0&src=IRS" target="_blank">Reservation</a>',
      'website':'<a href="https://www.buddhabar-dubai.com/" target="_blank">Website</a>'
    },
    'logo':'buddha'
  },
  {
    'id':'6_londres',
    'id_marker_1': 'marker_6_londres_1',
    'id_marker_2': 'marker_6_londres_2',
    'camera':{x: 0.060196548593959856, y: 0.834413967785356, z: 1.3254164050287935},
    'rotation':{x:0.4,y:0.3,z:0.5},
    'data':{
      'name':'LONDRES',
      'address':'145 Knightsbridge,London SW1X 7PA, Royaume-Uni',
      'tel':'020 3667 5222',
      'email':'<a href="https://www.sevenrooms.com/reservations/buddhabarlondon" target="_blank">Reservation</a>',
      'website':'<a href="http://www.buddhabarlondon.com/" target="_blank">Website</a>'
    },
    'logo':'buddha'
  },
  {
    'id':'7_marrakech',
    'id_marker_1': 'marker_7_marrakech_1',
    'id_marker_2': 'marker_7_marrakech_2',
    'camera':{x: -0.3309098359612301, y: 0.3431800418682568, z: 1.5493543890658485},
    'rotation':{x:0.2,y:0.1,z:0.1},
    'data':{
      'name':'MARRAKECH',
      'address':'Avenue Prince My Rashid, 40 000 MARRAKECH, MAROC',
      'tel':'+212 5244-59300',
      'email':'<a href="https://buddhabar-marrakech.com/reservation-and-content/" target="_blank">Reservation</a>',
      'website':'<a href="https://buddhabar-marrakech.com/" target="_blank">Website</a>'
    },
    'data_2':{
      'name':'Tamouda Bay',
      'address':'Banyan Tree Tamouda Bay, route de Fnideq, Fnideq 93100, Maroc',
      'tel':'+212539669999',
      'email':'<a href="https://www.banyantree.com/morocco/tamouda-bay/dining/buddha-bar-beach-rooftop" target="_blank">Reservation</a>',
      'website':'<a href="https://www.banyantree.com/morocco/tamouda-bay/dining/buddha-bar-beach-rooftop" target="_blank">Website</a>'
    },
    'logo':'buddha'
  },
  {
    'id':'8_monte_carlo',
    'id_marker_1': 'marker_8_monte_carlo_1',
    'id_marker_2': 'marker_8_monte_carlo_2',
    'camera':{x: 0.060196548593959856, y: 0.834413967785356, z: 1.3254164050287935},
    'rotation':{x:0.4,y:0.3,z:0.5},
    'data':{
      'name':'MONTE-CARLO',
      'address':'Place du Casino - 98000 Monaco, France',
      'tel':'+377 98 06 19 19',
      'email':'<a href="https://module.lafourchette.com/en_GB/module/19429-67587" target="_blank">Reservation</a>',
      'website':'<a href="https://www.montecarlosbm.com/en/bar-nightclub-monaco/buddha-bar-monte-carlo" target="_blank">Website</a>'
    },
    'logo':'buddha'
  },
  {
    'id':'9_paris',
    'id_marker_1': 'marker_9_paris_1',
    'id_marker_2': 'marker_9_paris_2',
    'camera':{x: 0.060196548593959856, y: 0.834413967785356, z: 1.3254164050287935},
    'rotation':{x:0.4,y:0.3,z:0.5},
    'data':{
      'name':'PARIS',
      'address':'8/12 rue Boissy d\'Anglas, 75008, Paris, FRANCE.',
      'tel':'+33 1 53 05 90 00',
      'email':'<a href="https://eu.sevenrooms.com/reservations/buddhabarparis" target="_blank">Reservation</a>',
      'website':'<a href="https://www.buddhabar.com/" target="_blank">Website</a>'
    },
    'data_2':{
      'name':'MONTE-CARLO',
      'address':'Place du Casino - 98000 Monaco, France',
      'tel':'+377 98 06 19 19',
      'email':'<a href="https://module.lafourchette.com/en_GB/module/19429-67587" target="_blank">Reservation</a>',
      'website':'<a href="https://www.montecarlosbm.com/en/bar-nightclub-monaco/buddha-bar-monte-carlo" target="_blank">Website</a>'
    },
    'logo':'buddha'
  },
  ,
  {
    'id':'10_pragues',
    'id_marker_1': 'marker_10_pragues_1',
    'id_marker_2': 'marker_10_pragues_2',
    'camera':{x: 0.060196548593959856, y: 0.834413967785356, z: 1.3254164050287935},
    'rotation':{x:0.6,y:0.2,z:0.5},
    'data':{
      'name':'PRAGUE RESTAURANT',
      'address':'Jakubskà 8 - 1, 110 00 Prague',
      'tel':'+420 221 776 400',
      'email':'<a href="https://www.buddha-bar.cz/en/uvod#" target="_blank">Reservation</a>',
      'website':'<a href="http://www.buddha-bar.cz/" target="_blank">Website</a>'
    },
    'data_2':{
      'name':'PRAGUE HOTEL',
      'address':'',
      'tel':'+420 221 776 300',
      'email':'<a href="https://www.buddhabarhotelprague.com/en/reservations/c_1" target="_blank">Reservation</a>',
      'website':'<a href="https://www.buddhabarhotelprague.com/" target="_blank">Website</a>'
    },
    'logo':'buddha'
  },
  {
    'id':'11_Saint-Pétersbourg',
    'id_marker_1': '',
    'id_marker_2': '',
    'camera':{x: 0.060196548593959856, y: 0.834413967785356, z: 1.3254164050287935},
    'rotation':{x:0.7,y:-0.5,z:0.1},
    'data':{
      'name':'SAINT-PETERSBOURG',
      'address':'StPetersburg, Sinopskaya Naberezhnaya, 78',
      'tel':'+7 (812) 318–07-07',
      'email':'<a href="mailto:info@buddha-bar.ru">Reservation</a>',
      'website':'<a href="http://www.buddha-bar.ru/" target="_blank">Website</a>'
    },
    'logo':'buddha'
  },

  {
    'id':'12_Sharm_El_Sheikh',
    'id_marker_1': 'marker_12_Sharm_El_Sheikh_1',
    'id_marker_2': 'marker_12_Sharm_El_Sheikh_2',
    'camera':{x: 0.060196548593959856, y: 0.834413967785356, z: 1.3254164050287935},
    'rotation':{x:0.2,y:-0.5,z:0.2},
    'data':{
      'name':'SHARM EL-SHEIKH',
      'address':'Naama Bay, Sharm Al Shiekh, South Sinai Governorate 46628, Égypte',
      'tel':'+20 128 204 1567',
      'email':'<a href="http://www.buddhabar-eg.com/reservation" target="_blank">Reservation</a>"',
      'website':'<a href="http://www.buddhabar-eg.com/" target="_blank">Website</a>'
    },
    'logo':'buddha'
  },
  //BEACH
  {
    'id':'21_mazagran',
    'id_marker_1': 'marker_21_mazagran_1',
    'id_marker_2': 'marker_21_mazagran_2',
    'camera':{x: -0.3309098359612301, y: 0.3431800418682568, z: 1.5493543890658485},
    'rotation':{x:0.2,y:0.1,z:0.1},
    'data':{
      'name':'MAZAGAN',
      'address':'Km 10, Route de Casablanca, El Jadida 24000, Maroc',
      'tel':'+212 5 23 38 80 80',
      'email':'<a href="mailto:reservations@mazaganbeachresort.com" target="_blank">Reservation</a>',
      'website':'<a href="https://www.mazaganbeachresort.com/fr/dining/buddha-bar-beach" target="_blank">Website</a>'
    },
    'logo':'beach'
  },
  {
    'id':'22_malta',
    'id_marker_1': 'marker_3_Belgrade003',
    'id_marker_2': 'marker_3_Belgrade003_1',
    'camera':{x: 0.060196548593959856, y: 0.834413967785356, z: 1.3254164050287935},
    'rotation':{x:0.4,y:0.3,z:0.5},
    'data':{
      'name':'MALTA',
      'address':'OPENING SOON',
      'tel':'',
      'email':'',
      'website':''
    },
    'logo':'beach'
  },
  {
    'id':'23_grece',
    'id_marker_1': 'marker_3_Belgrade004',
    'id_marker_2': 'marker_3_Belgrade004_1',
    'camera':{x: 0.060196548593959856, y: 0.834413967785356, z: 1.3254164050287935},
    'rotation':{x:0.4,y:0.3,z:0.5},
    'data':{
      'name':'CRETE',
      'address':'Themistokleous Ave - Hersonissos - Crete, Greece',
      'tel':'+30 28970 26410',
      'email':'<a href="mailto:info@abaton.gr" target="_blank">Reservation</a>',
      'website':'<a href="https://www.abaton.gr/" target="_blank">Website</a>'
    },
    'data_2':{
      'name':'MILOS',
      'address':'Adamantas 84800, Grèce',
      'tel':'+30 2287 022164',
      'email':'<a href="mailto:info@buddhabarbeachmilos.com" target="_blank">Reservation</a>',
      'website':'<a href="https://www.buddhabarbeachmilos.com/" target="_blank">Website</a>'
    },
    'data_3':{
      'name':'MYKONOS',
      'address':'Ornos Bay Mykonos, 84600, Greece',
      'tel':'+30 22890 26570',
      'email':'<a href="mailto:buddhabar@santa-marina.gr" target="_blank">Reservation</a>',
      'website':'<a href="https://www.santa-marina.gr/restaurants-bars/buddha-bar-beach-mykonos" target="_blank">Website</a>'
    },
    'data_4':{
      'name':'SANTORINI',
      'address':'Imerovigli Santorini 84700, Greece',
      'tel':'+30 2286025867',
      'email':'<a href="mailto:bbbsantorini@lamaltese.com" target="_blank">Reservation</a>',
      'website':'<a href="https://www.buddhabarbeachsantorini.com/" target="_blank">Website</a>'
    },
    'logo':'beach'
  },
  {
    'id':'24_bodrum',
    'id_marker_1': 'marker_3_Belgrade005',
    'id_marker_2': 'marker_3_Belgrade005_1',
    'camera':{x: 0.060196548593959856, y: 0.834413967785356, z: 1.3254164050287935},
    'rotation':{x:0.4,y:0.3,z:0.5},
    'data':{
      'name':'BODRUM',
      'address':'Adnan Menderes Caddesi No: 89, Asarlık Mevkii PK 225,Bodrum, Muğla Province 48400',
      'tel':'+ 90 2523113636',
      'email':'<a href="mailto:info@buddhabarbeachbodrum.com" target="_blank">Reservation</a>',
      'website':'<a href="https://www.buddhabarbeachbodrum.com/" target="_blank">Website</a>'
    },
    'logo':'beach'
  },
  {
    'id':'25_portomontenegro',
    'id_marker_1': 'marker_3_Belgrade002',
    'id_marker_2': 'marker_3_Belgrade002_1',
    'camera':{x: 0.060196548593959856, y: 0.834413967785356, z: 1.3254164050287935},
    'rotation':{x:0.4,y:0.3,z:0.5},
    'data':{
      'name':'PORTO MONTENEGRO',
      'address':'Blaza Jovanovica No 1 85320 Tivat Montenegro',
      'tel':'+382 32 661 059',
      'email':'<a href="mailto:buddhabar@portomontenegro.com" target="_blank">Reservation</a>',
      'website':'<a href="https://www.portomontenegro.com/marina/yacht-club/buddha-bar-beach/" target="_blank">Website</a>'
    },
    'logo':'beach'
  },
  {
    'id':'25_vevey',
    'id_marker_1': 'marker_3_Belgrade001',
    'id_marker_2': 'marker_3_Belgrade001_1',
    'camera':{x: 0.060196548593959856, y: 0.834413967785356, z: 1.3254164050287935},
    'rotation':{x:0.4,y:0.3,z:0.5},
    'data':{
      'name':'VEVEY',
      'address':'Grand hotel du lac, 1 rue d\'Italie, 1800 Vevey',
      'tel':'+021 925 06 06',
      'email':'<a href="https://module.lafourchette.com/fr_FR/module/486799-9acff" target="_blank">Reservation</a>',
      'website':'<a href="https://www.ghdl.ch/fr/restaurants-bars/buddha-bar-beach/" target="_blank">Website</a>'
    },
    'logo':'beach'
  },
  {
    'id':'25_abudhabi',
    'id_marker_1': 'marker_5_dubai001',
    'id_marker_2': 'marker_5_dubai001_1',
    'camera':{x: 0.060196548593959856, y: 0.834413967785356, z: 1.3254164050287935},
    'rotation':{x:0.3,y:-0.8,z:0.3},
    'data':{
      'name':'ABU DHABI',
      'address':'The St. Regis Saadiyat Island Resort, Saadiyat Island Abu Dhabi, United Arab Emirates',
      'tel':'+971 2 498 88 88',
      'email':'<a href="mailto:buddhabarbeach.abudhabi@stregis.com" target="_blank">Reservation</a>',
      'website':'<a href="https://www.buddhabarbeachabudhabi.com/" target="_blank">Website</a>'
    },
    'logo':'beach'
  },
  {
    'id':'25_mauritius',
    'id_marker_1': 'marker_5_dubai002',
    'id_marker_2': 'marker_5_dubai002_1',
    'camera':{x: 0.060196548593959856, y: 0.834413967785356, z: 1.3254164050287935},
    'rotation':{x:-0.65,y:-1.1,z:0.3},
    'data':{
      'name':'MAURITIUS',
      'address':'Wolmar, Flic en Flac, Mauritius',
      'tel':'+230 403 3300',
      'email':'<a href="mailto:Info.SBR@sugarbeachresort.mu" target="_blank">Reservation</a>',
      'website':'<a href="https://www.sugarbeachmauritius.com/dining/" target="_blank">Website</a>'
    },
    'logo':'beach'
  },
]
export default positions;